export enum KeyTable {
  ID = 'id',
  AI = 'ai',
  GRADE = 'gradeName',
  NAME_STUDENT = 'nickname',
  SCHOOL = 'schoolName',
  LAST_LOGIN = 'lastLoginAt',
  LASTEST = 'latestDrill',
  SU_PROCESS = 'su_process',
  SU_QUESTION = 'su_question',
  EI_PROCESS = 'ei_process',
  EI_QUESTION = 'ei_question',
  KO_PROCESS = 'ko_process',
  KO_QUESTION = 'ko_question',
  RI_PROCESS = 'ri_process',
  RI_QUESTION = 'ri_question',
  SH_PROCESS = 'sh_process',
  SH_QUESTION = 'sh_question',
}

export enum ActionSort {
  NonSort = 'none',
  ASC = 'asc',
  DESC = 'desc',
}

export interface SubjectResult {
  subjectName: string
  currentSUnitIds: number[]
  process: {
    current: number
    total: number
  }
  totalQuestion: number
}

export interface StudentHistory {
  rowIndex: string
  gradeName: string
  nickname: string
  studentId: number
  userId: number
  studentCode: string
  schoolName: string
  lastLoginAt: string
  latestDrill: {
    resultId: number
    title: string
    completedAt: string
  }
  subjectsResults: SubjectResult[]
}

export interface ResponseStudentHistory {
  students: StudentHistory[]
  page: number
  perPage: number
  totalPage: number
}
