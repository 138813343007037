




























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import RadioBase from '@/components/atoms/RadioBase.vue'
import Tooltip from '@/components/atoms/v3/Tooltip.vue'
import Sort from '@/components/atoms/v3/Sort.vue'
import IconLoading from '@/components/atoms/v3/IconLoading.vue'
import { Debounce } from 'vue-debounce-decorator'

@Component({
  components: {
    ButtonBase,
    CheckboxSquare,
    RadioToggle,
    RadioBase,
    Tooltip,
    Sort,
    IconLoading,
  },
})
export default class TableBase extends Vue {
  private small = false

  private striped = false

  private headVariant = null

  private bordered = false

  private borderless = false

  @Prop()
  perPage?: number

  @Prop({ default: true })
  hover?: boolean

  @Prop()
  items!: []

  @Prop()
  fields!: []

  @Prop()
  listText!: any

  @Prop()
  buttons?: []

  @Prop()
  checkboxs?: []

  @Prop()
  radios?: []

  @Prop()
  showHeaderCheckbox?: boolean

  @Prop()
  sorts?: []

  @Prop()
  texts?: []

  @Prop({ default: false })
  noBorderCollapse?: boolean

  @Prop({ default: [] })
  headerCheckboxs?: string[]

  @Prop()
  tooltips?: []

  @Prop()
  multipleLinks?: []

  @Prop()
  scrollBottom?: () => void

  @Prop()
  isLoadMore?: boolean

  @Prop()
  toggles?: []

  @Prop()
  multipleLines?: []

  @Prop({ default: 0 })
  number?: number

  @Prop()
  links?: []

  @Prop({ default: 0 })
  count?: number

  @Prop({ default: 1 })
  currentPage?: number

  @Prop({ default: false })
  stickyHeader?: boolean

  @Prop()
  radioName?: string

  @Prop()
  radioChecked?: number

  private isScrollBottom = false

  @Watch('items')
  onItemsChanged(newItems: any) {
    if (!this.checkboxs || !this.showHeaderCheckbox) return
    this.checkboxs.forEach((key: string) => {
      this.switchHeadCheckbox(key, newItems)
    })
  }

  private headerCheckboxChecked = this.getHeaderCheckboxChecked()
  @Debounce(500)
  private sortingChanged(ctx: any) {
    this.$emit('sort-changed', { [ctx.sortKey]: ctx.sortDirection })
  }

  private changeAllCheckbox(key: string, value: boolean) {
    this.items.forEach((item: any) => {
      item[key].checked = value
    })
  }

  private changeHeadCheckbox(key: string) {
    if (!this.showHeaderCheckbox && !this.headerCheckboxs?.length) return
    this.switchHeadCheckbox(key, this.items)
  }

  private switchHeadCheckbox(key: string, items: any[]) {
    const itemChecked = items.map((item: any) => {
      return item[key].checked
    })
    this.headerCheckboxChecked[key] = !itemChecked.includes(false)
  }

  private getHeaderCheckboxChecked() {
    const headerCheckboxChecked: { [key: string]: boolean } = {}
    if (!this.checkboxs || !this.showHeaderCheckbox) return headerCheckboxChecked
    this.checkboxs.forEach((key: string) => {
      headerCheckboxChecked[key] = false
    })
    return headerCheckboxChecked
  }

  private getCell(value: string): string {
    return `cell(${value})`
  }

  private getHeader(value: string): string {
    return `head(${value})`
  }

  private checkVariant(value: { [key: string]: string }): string {
    if (value.variant) {
      return value.variant
    }
    return 'outline-primary'
  }

  private checkurl(value: string): string | null {
    if (value) {
      return value
    }
    return null
  }

  // eslint-disable-next-line
  private checkclickButton(data: any, link: any): string | null {
    if (link.onclick) {
      // 変数の指定があればその変数を渡す TODO: 複数変数対応が必要？
      if (link.variable) {
        return link.onclick(link.variable)
      }
      return link.onclick(this.number, data.index)
    }
    return null
  }

  // eslint-disable-next-line
  private checkclick(data: any): string | null {
    if (data.value.onclick) {
      // 変数の指定があればその変数を渡す TODO: 複数変数対応が必要？
      if (data.value.variable) {
        return data.value.onclick(data.value.variable)
      }
      return data.value.onclick(this.number)
    }
    return null
  }

  // eslint-disable-next-line
  private checkcchangeRadio(data: any, checked: boolean): Function | null {
    if (data.value.onChange && data.value.onChange.func) {
      return data.value.onChange.func(data.value.onChange.funcParams, checked)
    }

    return null
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.$emit('paginate', page)
  }

  /**
   * ラジオボタン
   */
  private onRadioChange(value: string | number): void {
    this.$emit('inputRadio', value)
  }

  private handleScroll(e: Event) {
    const target = e.target as HTMLDivElement
    if (this.scrollBottom && target && !this.isLoadMore) {
      if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
        this.isScrollBottom = true
        this.scrollBottom()
      } else {
        this.isScrollBottom = false
      }
    }
  }

  mounted() {
    const table = document.querySelector('.b-table-sticky-header')
    table?.addEventListener('scroll', this.handleScroll)
  }
  beforeDestroy() {
    const table = document.querySelector('.b-table-sticky-header')
    table?.removeEventListener('scroll', this.handleScroll)
  }
}
