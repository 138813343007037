





import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import tippy, { Placement } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

@Component
export default class Tooltip extends Vue {
  @Prop()
  private id!: string
  @Prop({ default: true })
  private tooltip?: boolean
  @Prop({ default: '' })
  private className?: string

  @Prop({ default: false })
  private isLink?: boolean

  @Prop({ default: '' })
  content!: string

  @Prop({ default: 'light-border' })
  theme?: string

  @Prop()
  width?: number

  @Prop({ default: 'bottom' })
  placement!: Placement

  @Prop({ default: 'mouseenter focus' })
  trigger!: 'mouseenter focus' | 'click' | 'focusin' | 'mouseenter click' | 'manual' | 'mouseenter'

  protected tippy?: any

  private get styles() {
    return {
      ...(this.width ? { width: this.width + 'px' } : {}),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontFamily: 'sans-serif',
    }
  }

  protected mounted() {
    if (!this.tooltip) {
      return
    }
    this.initTippy()
  }

  protected initTippy() {
    // 外部ライブラリのtippyを使用
    // その他必要に応じてオプションを追加する場合は公式を参照(https://atomiks.github.io/tippyjs/)
    this.tippy?.[0] && this.tippy[0].destroy()
    this.tippy = tippy(`#${this.id}`, {
      content: this.content,
      placement: this.placement,
      trigger: this.trigger,
      theme: this.theme || 'light-border',
      maxWidth: 'none',
    })
  }

  /**
   * contentに変更があった場合、再度初期化を行う
   */
  @Watch('content')
  onChangeContent() {
    this.initTippy()
  }
}
