


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ActionSort } from '@/types/teacher'

@Component
export default class Sort extends Vue {
  @Prop()
  sortKey!: string
  @Prop()
  label?: string

  @Prop({ default: '#d9d9d9' })
  color?: string

  @Prop({ default: '#2c79ce' })
  colorActive?: string

  @Prop({ default: '16px' })
  size?: string

  @Prop({ default: ActionSort.NonSort })
  defaultSortDirection!: ActionSort

  @Prop({ default: ActionSort.ASC })
  defaultDirection?: ActionSort

  private sortDirection: ActionSort = this.defaultSortDirection

  private nextStatusSort() {
    if (this.defaultSortDirection === ActionSort.ASC) {
      this.nextDirection([ActionSort.ASC, ActionSort.DESC, ActionSort.NonSort])
      return
    }
    if (this.defaultSortDirection === ActionSort.DESC) {
      this.nextDirection([ActionSort.DESC, ActionSort.ASC, ActionSort.NonSort])
      return
    }
    if (this.defaultDirection === ActionSort.DESC) {
      this.nextDirection([ActionSort.NonSort, ActionSort.DESC, ActionSort.ASC])
      return
    }
    this.nextDirection([ActionSort.NonSort, ActionSort.ASC, ActionSort.DESC])
  }

  private nextDirection(direct: [ActionSort, ActionSort, ActionSort]) {
    const index = direct.findIndex((item) => item === this.sortDirection)
    if (index === 2) {
      this.sortDirection = direct[0]
    } else {
      this.sortDirection = direct[index + 1]
    }
    this.$emit('sort-changed', { sortDirection: this.sortDirection, sortKey: this.sortKey })
  }
}
