












import { Component, Prop, Vue } from 'vue-property-decorator'
import CheckboxBase from '@/components/atoms/CheckboxBase.vue'

@Component({
  components: {
    CheckboxBase,
  },
})
export default class CheckboxSquare extends Vue {
  @Prop()
  value!: string | number

  @Prop()
  id?: string

  @Prop()
  name?: string

  @Prop({ default: false })
  checked!: boolean

  @Prop({ default: false })
  disabled?: boolean

  get isChecked(): boolean {
    return this.checked
  }

  set isChecked(value: boolean) {
    this.checked = value
    this.$emit('input', value, this.id)
  }
}
