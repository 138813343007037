















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CheckboxBase extends Vue {
  @Prop()
  value!: string | number

  @Prop()
  id?: string

  @Prop()
  name?: string

  @Prop({ default: false })
  checked!: boolean

  @Prop({ default: false })
  disabled?: boolean

  get getId(): string {
    return this.id || `id${this.$uuid.v4()}`
  }

  get isChecked(): boolean {
    return this.checked
  }

  set isChecked(value: boolean) {
    this.checked = value
    this.$emit('input', value)
  }

  private clickEvent(key: Event): void {
    this.$emit('click-event', key)
  }
}
