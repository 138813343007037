














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SelectBase extends Vue {
  @Prop()
  selectOptions!: []

  @Prop({ default: null })
  onChangeFunction?: any

  @Prop({ default: null })
  width?: string | null

  @Prop({ default: null })
  border?: string | null

  @Prop({ default: null })
  radius?: string | null

  @Prop({ default: null })
  textColor?: string | null

  @Prop({ default: null })
  btnColor?: string | null

  @Prop({ default: null })
  placeholder?: string | null

  @Prop({ default: null })
  placeholderValue?: number | null

  @Prop({ default: null })
  bgColor?: string | null

  @Prop({ default: null })
  selected?: null | (string | number)

  @Prop({ default: false })
  disabled?: boolean

  get selectStyles(): { [key: string]: string } {
    return {
      '--width': this.width == null ? 'auto' : this.width,
      '--color': this.textColor == null ? 'var(--color-black)' : this.textColor,
    }
  }

  get baseStyles(): { [key: string]: string } {
    return {
      '--border': this.border == null ? '1px' : this.border,
      '--radius': this.radius == null ? '4px' : this.radius,
      '--btnColor': this.btnColor == null ? 'var(--color-blue)' : this.btnColor,
      '--bgColor': this.bgColor == null ? 'var(--color-white)' : this.bgColor,
    }
  }

  private changeSelect(event: Event): void {
    this.$emit('input', (event.target as HTMLSelectElement).value)
    if (this.onChangeFunction !== null) this.onChangeFunction(event)
  }

  private clickOption(event: Event): void {
    if (this.onChangeFunction !== null) this.onChangeFunction(event)
  }
}
