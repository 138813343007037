












import { Component, Prop, Model, Vue } from 'vue-property-decorator'

@Component
export default class RadioToggle extends Vue {
  private processing = false

  private onChange(): void {
    this.$emit('change-event', !this.value)
  }

  private onClick(event: Event): void {
    // 処理禁止かdisabledが設定されている場合は何もしない
    if (this.processing || this.disabled) {
      event.preventDefault()
      return
    }

    // click時にアラート表示設定があれば実行する
    if (this.radioToggleConfirm && this.radioToggleConfirm.isAlert) {
      if (!confirm(this.radioToggleConfirm.message)) {
        event.preventDefault()
        return
      }
    }

    // click時に事前処理があれば実行する
    if (this.radioToggleConfirm && this.radioToggleConfirm.onClick) {
      if (!this.radioToggleConfirm.onClick(this.radioToggleConfirm.onClickParams)) {
        event.preventDefault()
        return
      }
    }

    // 一度申請したらクリック禁止にする
    if (this.immediatDisabled) this.processing = true
  }

  @Prop()
  id!: string

  @Prop()
  size!: string

  @Prop()
  onLabel!: string

  @Prop()
  offLabel!: string

  @Prop({ default: false })
  disabled?: boolean

  @Prop({ default: false })
  immediatDisabled?: boolean

  @Prop({ default: null })
  radioToggleConfirm?: { [key: string]: any } | null

  @Model('change-event', { type: Boolean, default: false })
  value?: boolean
}
