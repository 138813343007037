
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class RadioBase extends Vue {
  @Prop()
  name!: string

  @Prop()
  value!: string | number

  @Prop()
  id?: string

  @Prop({ default: false })
  checked?: boolean

  @Prop({ default: true })
  visibleInput?: boolean

  get getId() {
    return this.id || `id${this.$uuid.v4()}`
  }

  get setStyleInfo(): { [key: string]: string } {
    return {
      '--input-display': this.visibleInput ? 'auto' : 'none',
    }
  }

  private onChange(event: Event): void {
    if ((event.target as HTMLInputElement).checked) {
      this.$emit('input', this.value)
    }
  }
}
